import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody, IconButton, TextField } from "@mui/material";
import React, { useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { ComponentModal, IProduct } from "../common/interfaces";
import { usePosStore } from "../context/posStore";
import { useModalStore } from "../context/modalStore";

export const CartView: React.FC = () => {
  const messagesEndRef = React.useRef<HTMLTableElement>(null);
  const products = usePosStore((state) => state.products)
  const setProducts = usePosStore((state) => state.setProducts)
  const modalManager = useModalStore()
  useEffect(() => {
    scrollToBottom();
  }, [products])

  const scrollToBottom = () => messagesEndRef.current?.scrollIntoView(false)
  const deleteItem = (row: IProduct): void => {
    modalManager.openModal(ComponentModal.CancelItem, {item: row})
  };

  return (
    <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
      <Table
        sx={{ minWidth: "80%" }}
        aria-label="simple table"
        style={{ backgroundColor: "#333", color: "#fff" }}
        ref={messagesEndRef}
      >
        <TableHead>
          <TableRow>
            <TableCell width={"10%"}></TableCell>
            <TableCell align="left" width={"20%"}>
              Cantidad
            </TableCell>
            <TableCell width={"30%"}>Producto</TableCell>
            <TableCell width={"10%"}>Precio</TableCell>
            <TableCell align="right" width={"10%"}>
              IVA
            </TableCell>
            <TableCell align="right" width={"10%"}>
              IEPS
            </TableCell>
            <TableCell align="right" width={"10%"}>
              TOTAL
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((row) => {
            const iva = row.iva?.porcentaje ?? 0;
            const ieps = row.ieps?.porcentaje ?? 0;
            const quantity = Number(row.quantity) ?? 1;
            const lineIva = row.price_1 * iva * Number(quantity);
            const lineIeps = row.price_1 * ieps * Number(quantity);
            const subTotal = row.price_1 * Number(quantity);
            const total = subTotal + lineIva + lineIeps;

            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="left" component="th" scope="row">
                  <IconButton
                    aria-label="delete"
                    onClick={() => deleteItem(row)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  <TextField
                    type="number"
                    // inputProps={{
                    //   inputMode: "numeric",
                    // }}
                    value={row.quantity}
                    onBlur={(event) => {
                      let value = event.target.value;
                      // console.log("blur field", parseFloat(event.target.value));
                      const newProducts = products.map(
                        (product: IProduct) => {
                          if (product.id === row.id) {
                            if (
                              parseFloat(value) <= 0 ||
                              isNaN(parseFloat(value))
                            ) {
                              product.quantity = 1;
                            }
                          }
                          return product;
                        }
                      );
                      setProducts(newProducts);
                    }}
                    onChange={(event) => {
                      let value = event.target.value;
                      const newProducts = products.map(
                        (product: IProduct) => {
                          if (product.id === row.id) {
                            if (parseFloat(value) < 0) {
                              product.quantity = 1;
                            } else {
                              console.log("is_bulk", product.is_bulk === "1");
                              if (product.is_bulk === "1") {
                                product.quantity = parseInt(value);
                              } else {
                                product.quantity = value;
                              }
                            }
                          }
                          return product;
                        }
                      );
                      setProducts(newProducts);
                    }}
                  />
                </TableCell>
                <TableCell align="left">
                  {row.name} <br />
                  {row.selectedOptions
                    ? row.selectedOptions.split(",").map((e: string) => {
                        return (
                          <div key={e}>
                            - {e} <br />
                          </div>
                        );
                      })
                    : ""}{" "}
                  {/* {row.assembled_products
                    ? row.assembled_products.map((product: IProduct) => {
                        return (
                          <div key={product.id}>
                            * {product.name} <br />
                          </div>
                        );
                      })
                    : ""} */}
                </TableCell>
                <TableCell align="right">{row.price_1}</TableCell>
                <TableCell align="right">{lineIva.toFixed(2)}</TableCell>
                <TableCell align="right">{lineIeps.toFixed(2)}</TableCell>
                <TableCell align="right">{total.toFixed(2)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
