/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Paper, TableContainer, Tooltip, Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  EMapStatusSale,
  TChashBoxDetails,
  mappingOperations,
} from "../common/interfaces";
import { CashBoxService } from "../services/CashBoxService";
import { useLocalStorage } from "../auth/useLocalStorage";
import moment from "moment";
import PrintIcon from "@mui/icons-material/Print";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CancelIcon from "@mui/icons-material/Cancel";
// import { usePusher } from "../context/pusherContext";
import { v4 } from "uuid";
import { CommonService } from "../services/CommonService";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import withReactContent from "sweetalert2-react-content";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useModalStore } from "../context/modalStore";

import { reprintBill } from "./utilsButtons";

const MySwal = withReactContent(Swal);

interface MovementsProps {
  onlyOrders?: boolean;
  reprintOrder: (idVenta: number) => void;
  reprintRPC: (idOperation: number) => void;
  reprintSale: (idVenta: number) => void;
  closeOrder: (idVenta: number) => void;
}

enum OrderType {
  shipped = "A domicilio",
  cocina = "Cocina",
  onSite = "Recoger en Tienda",
  inRoom = "Comedor",
}

export const Movements = (props: MovementsProps) => {
  const { reprintOrder, reprintSale, closeOrder, reprintRPC } =
    props;
  const modalManager = useModalStore();
  const onlyOrders = modalManager?.params?.onlyOrders
  // onlyOrders ? 'Pedidos' : 'Movimientos del Día'
  const [rows, setRows] = React.useState<Array<TChashBoxDetails>>(
    [] as TChashBoxDetails[]
  );
  const [user] = useLocalStorage("user", "");
  // const pusher = usePusher();

  useEffect(() => {
    modalManager.setTitle(onlyOrders ? "Pedidos" : "Movimientos del Día");
    if (onlyOrders) {
      if (user && user.cbox && user.cbox.id != null) {
        CashBoxService.getMovements(user.cbox.id, 1).then((res) => {
          setRows(res.object as Array<TChashBoxDetails>);
        });
      }else{
        console.log("No cashbox.");
      }
    } else {
      if (user && user.cbox && user.cbox.id != null) {
        CashBoxService.getMovements(user.cbox.id).then((res) => {
          setRows(res.object as Array<TChashBoxDetails>);
        });
      }else{
        console.log("No cashbox.");
      }
    }
  }, []);
  function cancelOrder(operation_id: number): void {
    const idCancel = v4();
    CommonService.getSaleInfo(operation_id).then((res) => {
      if(res.success){

        CommonService.requestAuthorize({
          message: `Se solicita Cancelar Pedido: ${operation_id}`, // => $req->message,
          action: {
            type: "cancel-order",
            item: { order_id: operation_id },
            id: idCancel,
            content: `Se solicita Cancelar Pedido: <br/> Pedido ${operation_id} <br /> Total: ${res.object?.total} <br /> Productos: <br> ${res.object?.detalles.map(detail => `(${detail.producto.id}) ${detail.producto.name} Cantidad: ${detail.cantidad}`).join('<br>')}`,
          }, // => $req->action,
          status: "pending", // => 'pending', // Set the initial status
          from: user.id, // => $req->from,
          to: 1, // => $req->to,
        }).then((res) => {
          console.log("rquest", { res });
          MySwal.fire({
            icon: "success",
            title: "Se ha solicitado autorización para cancelar el pedido.",
            showConfirmButton: false,
            width: "400px",
            timer: 1500,
          });
        });
      }
    })
  }

  function cancelTransfer(operation_id: number, amount: any): void {
    const idCancel = v4();
    CommonService.requestAuthorize({
      message: `Se solicita Cancelar Transferencia: ${operation_id}`, // => $req->message,
      action: {
        type: "cancel-transfer",
        item: { transfer: operation_id },
        id: idCancel,
        content: `Se solicita Cancelar Transferencia: ${operation_id} <br /> de $ ${amount}`,
      }, // => $req->action,
      status: "pending", // => 'pending', // Set the initial status
      from: user.id, // => $req->from,
      to: 1, // => $req->to,
    }).then((res) => {
      console.log("rquest", { res });
      MySwal.fire({
        icon: "success",
        title: "Se ha solicitado autorización para cancelar transferencia de Inventario.",
        showConfirmButton: false,
        width: "400px",
        timer: 1500,
      });
    });
  }

  function createAuth(operation_id: number, amount: string): void {
    const idAuth = v4();
    CommonService.requestAuthorize({
      message: `Se solicita Autorizar un pago por Transferencia de $ ${amount}`, // => $req->message,
      action: {
        type: "authorize-payment",
        item: { order_id: operation_id },
        id: idAuth,
        content: `Se solicita Autorizar un pago por Transferencia de $ ${amount}`,
      }, // => $req->action,
      status: "pending", // => 'pending', // Set the initial status
      from: user.id, // => $req->from,
      to: 1, // => $req->to,
    }).then(() => {
      CommonService.toast("Se envío solicitud de Autorización, para validar pago por transferencia", "success")
      // modalManager.closeModal();
    });
  }

  // Payment method mapping
  const paymentMethodMap: Record<string, string> = {
    cash: "Efectivo",
    transfer: "Transferencia",
    credit_card: "Tarjeta de crédito/Débito",
    gift_card: "Tarjeta de Regalo",
  };

  return (
    <div>
      {/* <Typography style={{ fontSize: "35px" }}>{onlyOrders ? 'Pedidos' : 'Movimientos del Día'}</Typography> */}
      <TableContainer component={Paper}>
        <table
          style={{
            width: "100%",
            fontSize: "14px",
            borderCollapse: "collapse",
          }}
        >
          <thead>
            <tr>
              {/* <th style={{width:"10%", borderBottom: "1px solid #ccc"}} align="center">Operacion</th> */}
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                ID
              </th>
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Origen
              </th>
              <th
                style={{ width: "20%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Cliente
              </th>
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Monto
              </th>
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Hora
              </th>
              {/* <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Tipo
              </th> */}
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Entrega
              </th>
              <th
                style={{ width: "10%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Estatus
              </th>

              <th
                style={{ width: "8%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Método de Pago
              </th>
              <th
                style={{ width: "20%", borderBottom: "1px solid #ccc" }}
                align="center"
              >
                Acciones
              </th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, i) => (
              <tr
                key={i}
                style={{ borderBottom: "1px solid #ccc" }}
                className={
                  row.payment_status === 'pending' ? "background_red" : row.payment_status === 'paid' ? "background_green"

                  : row.type === "sale" || row.type === 'out' || row.type === 'transfer_inventory' || row.operation === 'bill'
                    ? !row.payments || row.ss === "pending" || row.operation === 'purchase_orders' || row.status === 'Pendiente'
                      ? (
                        row.type === 'transfer_inventory' 
                          ? 
                          row.status === 'Pendiente' ? "background_red" : "background_green" 
                          : row.operation === 'bill' ? 
                          row.status === 'Pendiente' ? "background_red" : "background_green" 
                          : "background_red"
                      ) 
                      : "background_green"
                    : ""
                }
              >
                {/* <td align="center" style={{borderBottom: "1px solid #ccc"}}>
                {row.operation}
              </td> */}
                <td align="center" style={{ borderBottom: "1px solid #ccc" }}>
                  {mappingOperations[row.operation].code + row.operation_id}
                </td>
                <td align="center" style={{ borderBottom: "1px solid #ccc" }}>
                  {row.origin_by === "WEB-POS" ? `WEB-${row.alias_id}` : row.origin_by}
                </td>
                <td align="left" style={{ borderBottom: "1px solid #ccc" }}>
                  <Tooltip title={<Typography fontSize={30}>{row.telefono || "Teléfono no disponible"}</Typography>} arrow>
                    <span>{row.nombre === "P\u00daBLICO EN GENERAL" ? "" : row.nombre}</span>
                  </Tooltip>
                  {/* {row.nombre === "P\u00daBLICO EN GENERAL" ? "" : row.nombre} */}
                </td>
                <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {row.amount}
                </td>
                
                <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {moment(row.created_at).format("h:mm A")}
                </td>
                {/* <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {mappingOperations[row.operation].label}
                </td> */}
                <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {OrderType[
                    row.domicilio as unknown as keyof typeof OrderType
                  ] ?? "Regular"}
                </td>
                <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {row.ss === "pending"
                    ? "Esperando Autorización"
                    : EMapStatusSale[row.status as keyof typeof EMapStatusSale]}
                </td>
                <td align="right" style={{ borderBottom: "1px solid #ccc" }}>
                  {paymentMethodMap[row.payment_method ?? "cash"]}
                </td>
                <td
                  align="left"
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingLeft: "20px",
                  }}
                  hidden={row.operation === "venta"}
                >
                  {row.operation === "purchase_orders_receipt" && (
                    <Button
                      title="Imprimir Ticket"
                      style={{
                        fontSize: "10px",
                        width: "35px",
                        minWidth: "auto",
                      }}
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() => reprintRPC(row.operation_id)}
                    >
                      <PrintIcon />
                    </Button>
                  )}
                  {
                    row.operation === 'warehouse_transfer' && row.status === 'Pendiente' && (
                      <Button
                        title="Cancelar Transferencia"
                        style={{
                          fontSize: "10px",
                          width: "35px",
                          minWidth: "auto",
                          marginLeft: "5px",
                        }}
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => cancelTransfer(row.operation_id, row.amount)}
                      >
                        <CancelIcon />
                      </Button>
                    )
                  }

                  {
                    row.operation === 'bill' && row.status === 'Complete' && (
                      <Button
                      title="Imprimir Ticket"
                      style={{
                        fontSize: "10px",
                        width: "35px",
                        minWidth: "auto",
                      }}
                      variant="contained"
                      size="small"
                      color="info"
                      onClick={() => reprintBill(row.operation_id)}
                    >
                      <PrintIcon />
                    </Button>
                    )
                  }
                </td>
                <td
                  align="left"
                  style={{
                    borderBottom: "1px solid #ccc",
                    paddingLeft: "20px",
                  }}
                  hidden={row.operation !== "venta"}
                >
                  {row.sale_type === "Order" && (
                    <Button
                      title="Imprimir Comanda"
                      style={{
                        fontSize: "10px",
                        width: "35px",
                        minWidth: "auto",
                        marginLeft: "5px",
                      }}
                      variant="contained"
                      size="small"
                      color="success"
                      onClick={() => reprintOrder(row.operation_id)}
                    >
                      <ContentPasteSearchIcon />
                    </Button>
                  )}
                  {row.payment_status === "paid" && (
                    <Button
                      title="Imprimir Ticket"
                      style={{
                        fontSize: "10px",
                        width: "35px",
                        minWidth: "auto",
                        marginLeft: "5px",
                      }}
                      variant="contained"
                      size="small"
                      color="info"
                      onClick={() => reprintSale(row.operation_id)}
                    >
                      <PrintIcon />
                    </Button>
                  )}
                  {row.payment_status === "pending" && row.ss !== "pending" && (
                    <>
                      <Button
                        title="Pagar Pedido"
                        style={{
                          fontSize: "10px",
                          width: "35px",
                          minWidth: "auto",
                          marginLeft: "5px",
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => closeOrder(row.operation_id)}
                      >
                        <AttachMoneyIcon />
                      </Button>
                      <Button
                        title="Cancelar Pedido"
                        style={{
                          fontSize: "10px",
                          width: "35px",
                          minWidth: "auto",
                          marginLeft: "5px",
                        }}
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => cancelOrder(row.operation_id)}
                      >
                        <CancelIcon />
                      </Button>
                    </>
                  )}
                  {row.ss === "pending" && (
                    <>
                      <Button
                        title="Reenviar Solicitud de Aprobación"
                        style={{
                          fontSize: "10px",
                          width: "35px",
                          minWidth: "auto",
                          marginLeft: "5px",
                        }}
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => createAuth(row.operation_id, row.amount)}
                      >
                        <AttachMoneyIcon />
                      </Button>
                      <Button
                        title="Cancelar Pedido"
                        style={{
                          fontSize: "10px",
                          width: "35px",
                          minWidth: "auto",
                          marginLeft: "5px",
                        }}
                        variant="contained"
                        size="small"
                        color="error"
                        onClick={() => cancelOrder(row.operation_id)}
                      >
                        <CancelIcon />
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableContainer>
    </div>
  );
};
