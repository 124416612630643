import {
  Snackbar,
  Alert,
  AlertColor,
  Button,
  ButtonGroup,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
  IconButton,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  ICustomer,
  IGifCard,
  IPayment,
  ISale,
  PaymentsMethods,
  PaymentsMethodsLabel,
  TPaymentMethod,
} from "../common/interfaces";
import { SaleService } from "../services/SaleService";
import { CommonService } from "../services/CommonService";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import DeleteIcon from "@mui/icons-material/Delete";
import {v4} from "uuid"
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css"
import withReactContent from "sweetalert2-react-content";
import { useLocalStorage } from "../auth/useLocalStorage";
import { CustomerService } from "../services/CustomerService";
import moment from "moment";
const MySwal = withReactContent(Swal);

interface PayOrderProps {
  // total?: number;
  // paymentMethod: string;
  // setPaymentMethod: (value: string) => void;
  // amountToPayChash: number;
  saleId: number;
  handleCancel: () => void;
  openTicket: (idSale: number) => void;
  closeModal?: () => void;
}

export const PayOrder = (props: PayOrderProps) => {
  const { saleId, handleCancel, openTicket, closeModal } = props;
  const [paymentMethod, setPaymentMethod] =
    React.useState<TPaymentMethod>("other");
  const [disableSend, setDisableSend] = React.useState(false)
  const [amountToPayChash, setAmountToPayChash] = React.useState(0);
  const [cashReceived, setCashReceived] = React.useState(0);
  const [amountToPayDebitCard, setAmountToPayDebitCard] = React.useState(0);
  const [amountToPayCreditCard, setAmountToPayCreditCard] = React.useState(0);
  const [authCode, setAuthCode] = React.useState("");
  const [total, setTotal] = React.useState(0);
  const [order, setOrder] = React.useState({} as ISale);
  const [user, setUser] = useLocalStorage("user", null);
  const [customer, setCustomer] = useState<ICustomer>();
  const mapPaymentsMethods = {
    cash: "Efectivo",
    credit_card: "Tarjeta de Crédito",
    debit_card: "Tarjeta de Débito",
    gift_card: "Tarjeta de Regalo",
    vales: "Vales de despensa",
    usd: "Dólares",
    other: "Otros",
    transfer: "Transferencia",
    credit: "Crédito",
  };
  const [isValidGiftCard, setIsVaLidGiftCard] = useState(false);
  const [gifCardInformation, setGifCardInformation] = useState({} as IGifCard);
  const [amountToPay, setAmountToPay] = useState("");
  const [amountToPayUsd, setAmountToPayUsd] = useState("");
  const [exchangeRate, setExchangeRate] = React.useState(0)
  const [amountReceived, setAmountReceived] = useState("");
  const [payments, setPayments] = useState<IPayment[]>([]);
  const setNewPayment = (method: TPaymentMethod) => {
    setPaymentMethod(method);
    const auxTotal =
      total - payments.reduce((acc, curr) => acc + curr.amount, 0);
    setAmountToPay(auxTotal.toFixed(2));
    setAmountToPayUsd((auxTotal / exchangeRate).toFixed(2));
    setAmountReceived("");
  };

  // const deletePayment = (id: number) => {
  //   const aux = payments;
  //   aux.splice(id, 1);
  //   const auxTotal = total - aux.reduce((acc, curr) => acc + curr.amount, 0);
  //   setAmountToPay(auxTotal.toFixed(2));
  //   setAmountToPayUsd((auxTotal / exchangeRate).toFixed(2));
  //   setAmountReceived("");
  //   setAuthCode("");
  //   setIsVaLidGiftCard(false);
  //   setGifCardInformation({} as IGifCard);
  //   setPaymentMethod("other");
  //   setPayments([...aux]);
  // };

  const deletePayment = (id: number) => {
    const aux = [...payments];
    aux.splice(id, 1);
    const auxTotal = total - aux.reduce((acc, curr) => acc + curr.amount, 0); // No adjustment to `total`
    // setAmountToPay(auxTotal.toFixed(2));
    setAmountToPay("");

    setAmountToPayUsd((auxTotal / exchangeRate).toFixed(2));
    setAmountReceived("");
    setAuthCode("");
    setIsVaLidGiftCard(false);
    setGifCardInformation({} as IGifCard);
    // setPaymentMethod("other");
    setPayments(aux); // Use the updated array after splice
  };


  const handleEnter = (evt: any) => {
    if (evt.key === "Enter") {
      const aux = payments.reduce((acc, curr) => acc + curr.amount, 0);
      const finalizar =
        parseFloat((aux + parseFloat(amountToPay)).toFixed(2)) - total >= 0;
      console.log({ finalizar });
      if (
        paymentMethod === "cash" ||
        paymentMethod === "credit_card" ||
        paymentMethod === "debit_card" ||
        paymentMethod === "usd"
      ) {
        if (finalizar) {
          payOrder();
          return;
        }
        addPayment();
      }
    }
  };

  const addPayment = () => {
    if (isValidGiftCard === false && paymentMethod === "gift_card") {
      CommonService.displayAlert("La tarjeta de regalo no es valida, o no ha sido verificada.");

      return;
    }

    console.log("amountToPay");
    console.log(amountToPay);

    if(!amountToPay && !parseFloat(amountToPay)){
      CommonService.displayAlert("Seleccione un Método de Pago.");
      return;
    }
    const payment: IPayment = {
      amount: parseFloat(amountToPay),
      payment_method: paymentMethod as TPaymentMethod,
      cash_received: parseFloat(amountToPay),
      auth_code: authCode,
      usd: parseFloat(amountToPayUsd),
      exchange_rate: exchangeRate,
      status: "approved",
    };
    const aux =
      total -
      payments.reduce((acc, curr) => acc + curr.amount, 0) -
      payment.amount;
    console.log({ aux });
    setAmountToPay(aux.toFixed(2));
    setAmountToPayUsd((aux / exchangeRate).toFixed(2));
    setAmountReceived("");
    setAuthCode("");
    setIsVaLidGiftCard(false);
    setGifCardInformation({} as IGifCard);
    setPaymentMethod("other");
    setPayments([...payments, payment]);
  };
  const validateGifCard = () => {
    CommonService.getGifCardInformation(authCode).then((res) => {
      if (res.success && res.object?.status === "Active") {
        setIsVaLidGiftCard(true);
        setGifCardInformation(res.object as IGifCard);
        return;
      }
      CommonService.displayAlert("La tarjeta de regalo no es valida");
      return;
    });
  };
  useEffect(() => {
    SaleService.getSale(saleId).then((response) => {
      setOrder(response.object as ISale);
      if (response.object) {
        setTotal(parseFloat(response.object.total));
        CustomerService.getCustomerById(response.object?.cliente_id).then((res) => {
          if(res.success) setCustomer(res.object)
        })
      } else {
        setTotal(0);
      }
    });

 
      CommonService.getExchangeRate().then((res) => {
        if (res.success) {
          setExchangeRate(res.object.rate)
        }
      })
  
    

  }, []);

  // Fetch Payments when Order Changes
  useEffect(() => {
    if (order?.payments && payments.length === 0) {
      console.log("Pagos registrados: ", order.payments);
      // Verificar que todos los amounts sean números válidos
      const sanitizedPayments = order.payments.map((payment) => ({
        ...payment,
        amount: typeof payment.amount === "string" ? parseFloat(payment.amount) : payment.amount,
      }));

      console.dir(sanitizedPayments);

      // setPayments(sanitizedPayments);
      // setAmountToPay("0");
      const anticipos = sanitizedPayments.reduce((acc, curr) => acc + curr.amount, 0);

      // Set total
      const orderTotal = parseFloat(order.total);

      setTotal(orderTotal - anticipos);
      
    }
  }, [order, payments]);

  const payOrder = () => {
    setDisableSend(true)
    const data = {
      payments: [
        ...payments,
        // {
        //   amount:
        //     parseFloat(amountToPay) > (total - payments.reduce((acc, curr) => acc + curr.amount, 0)) ? parseFloat((total - payments.reduce((acc, curr) => acc + curr.amount, 0)).toFixed(2)) : parseFloat(amountToPay),
        //   payment_method: paymentMethod,
        //   cash_received:
        //     paymentMethod === "cash" || paymentMethod === "vales" || paymentMethod === "usd"
        //       ? parseFloat(amountToPay)
        //       : 0,
        //   auth_code: authCode,
        //   exchange_rate: exchangeRate,
        //   usd: parseFloat(amountToPayUsd),
        //   status: paymentMethod === "transfer" || paymentMethod === "credit" ? "pending": "approved",
        // },
      ],
    };

    // let auxPayments =
    //   data.payments?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
    let auxPayments = 0;

    data.payments.forEach(payment => {
      if (payment.amount) {
        auxPayments += typeof payment.amount === "string" ? parseFloat(payment.amount) : payment.amount;
      }
    });
    // data.payments?.reduce(
    //   (acc, curr) => acc + (typeof curr.amount === "string" ? parseFloat(curr.amount) : curr.amount),
    //   0
    // ) ?? 0;

    auxPayments = parseFloat(auxPayments.toFixed(2));

    console.log("Aux payments: ");
    console.log(auxPayments);

    if (auxPayments < total) {
      CommonService.displayAlert("Ingrese monto que cubra el total de la orden");
      setDisableSend(false)
      return;
    }

    SaleService.payOrder(saleId, data).then((response) => {
      if (response.success) {
        // CommonService.displayAlert("Pago exitoso");
        if (paymentMethod === 'credit') {
          const idAuth = v4();
          CommonService.requestAuthorize({
            message: `Se solicita Autorizar un pago a Crédito de $ ${total.toFixed(2)}`, // => $req->message,
            action: {
              type: "authorize-payment",
              item: { order_id: saleId },
              id: idAuth,
              content: `Se solicita Autorizar un pago a Crédito de $ ${total.toFixed(2)}`,
            }, // => $req->action,
            status: "pending", // => 'pending', // Set the initial status
            from: user.id, // => $req->from,
            to: 1, // => $req->to,
          }).then(() => {
            CommonService.toast(
              "Se envío solicitud de Autorización, para validar pago a Crédito",
              "success"
            );
            if(closeModal){
              closeModal();
            }
          });
        } else if(paymentMethod === 'transfer'){
          const idAuth = v4()
          CommonService.requestAuthorize({
              message: `Se solicita Autorizar un pago por Transferencia de $ ${total.toFixed(2)}`, // => $req->message,
              action: {
                type: "authorize-payment",
                item: {order_id: saleId},
                id: idAuth,
                content: `Se solicita Autorizar un pago por Transferencia de $ ${total.toFixed(2)}`
              }, // => $req->action,
              status: 'pending', // => 'pending', // Set the initial status
              from: user.id, // => $req->from,
              to: 1, // => $req->to,
          }).then(() => {
            MySwal.fire({
              icon: "success",
              title: 'Se envío notificación para validar Pago por transferencia',
              showConfirmButton: false,
              timer: 1500,
              width: '500px',
            });
            if(closeModal){
              closeModal();
            }
          })
          // pusher.
        }else{
          openTicket(saleId);
        }
        setDisableSend(false)
      }
    });
  };

  return (
    <div style={{ textAlign: "center" }}>

      {/* Sección para mostrar anticipos */}
      <div>
        <Typography variant="h6">Anticipos:</Typography>
        {order?.payments && order.payments.length > 0 ? (
          <TableContainer component={Paper} style={{ maxHeight: "200px", overflowY: "auto" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Fecha y Hora</TableCell>
                  <TableCell>Método de Pago</TableCell>
                  <TableCell>Monto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order.payments.map((payment, index) => (
                  <TableRow key={index}>
                    <TableCell>{moment(payment.created_at).format("DD/MM/YYYY h:mm A")}</TableCell>
                    <TableCell>{mapPaymentsMethods[payment.payment_method]}</TableCell>
                    {/* <TableCell>{new Date(payment.created_at).toLocaleString()}</TableCell> */}
                    <TableCell>$ {payment.amount}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <tfoot>
                <TableRow>
                  <TableCell colSpan={2} style={{ textAlign: "right", fontWeight: "bold" }}>
                    Total de Anticipos:
                  </TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>
                    $ {order.payments.reduce((acc, payment) => acc + parseFloat(String(payment.amount || "0")), 0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </tfoot>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No hay anticipos registrados.</Typography>
        )}
      </div>

      <div style={{ marginTop: "20px" }}></div>

      <Typography>Total a pagar:</Typography>
      <Typography fontSize={35}>$ {total.toFixed(2)} MXN</Typography>
      <ButtonGroup
        size="large"
        aria-label="large button group"
        style={{ marginTop: "20px" }}
      >
        <Button key="one" onClick={() => setNewPayment("cash")}>
          Efectivo
        </Button>
        <Button key="one" onClick={() => setNewPayment("usd")}>
          Dólares
        </Button>
        <Button key="two" onClick={() => setNewPayment("credit_card")}>
          Tarjeta de Crédito
        </Button>
        <Button key="three" onClick={() => setNewPayment("debit_card")}>
          Tarjeta de Débito
        </Button>
        <Button key="four" onClick={() => setNewPayment("gift_card")}>
          Tarjeta de Regalo
        </Button>
        <Button key="five" onClick={() => setNewPayment("vales")}>
          Vales
        </Button>
        <Button key="six" onClick={() => setNewPayment("transfer")}>
          Transferencia 
        </Button>
      </ButtonGroup>
        {customer?.allow_credit === 1 && customer?.current_credit && customer?.current_credit >= Number(order.total) && (
        <ButtonGroup
          size="large"
          aria-label="large button group"
          style={{ marginTop: "20px" }}
        >
          <Button
            key="eight"
            onClick={() => setNewPayment(PaymentsMethods.Credit)}
          >
            {PaymentsMethodsLabel.Credit}
          </Button>
        </ButtonGroup>
      )}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            fontSize: "20px",
          }}
        >
          {mapPaymentsMethods[paymentMethod]}
        </Typography>
        {paymentMethod === "cash" ? (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onKeyDown={handleEnter}
                onChange={(event) => {
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>

            {amountToPay !== "" ? (
              <>
                {parseFloat((total - parseFloat(amountToPay)).toFixed(2)) >
                0 ? (
                  <>{}</>
                ) : (
                  <>
                    <Typography style={{ marginTop: "20px" }}>
                      {"Cambio"}
                    </Typography>
                    <Typography fontSize={35}>
                      ${" "}
                      {amountToPay.toString() === "NaN"
                        ? "0.00"
                        : (parseFloat(amountToPay) - total).toFixed(2)}{" "}
                      MXN
                    </Typography>
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        {paymentMethod === "credit_card" || paymentMethod === "debit_card" ? (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  if (
                    parseFloat(event.target.value) > parseFloat(amountToPay)
                  ) {
                    setAmountToPay(amountToPay);
                  }
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} style={{ width: "230px" }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Código de Autorización
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Codigo"
                onKeyDown={handleEnter}
                type="text"
                value={authCode}
                onChange={(event) => {
                  setAuthCode(event.target.value);
                }}
              />
            </FormControl>
          </>
        ) : (
          ""
        )}

        {paymentMethod === "gift_card" ? (
          <>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  if (
                    parseFloat(event.target.value) > parseFloat(amountToPay)
                  ) {
                    setAmountToPay(amountToPay);
                  }
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} style={{ width: "230px" }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Codigo
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Codigo"
                type="text"
                value={authCode}
                onChange={(event) => {
                  setAuthCode(event.target.value);
                }}
                endAdornment={
                  <IconButton>
                    {isValidGiftCard ? (
                      <CheckCircleOutlineIcon />
                    ) : (
                      <BlockIcon />
                    )}
                  </IconButton>
                }
              />
            </FormControl>
            <div>
              {gifCardInformation.id ? (
                <div
                  style={{
                    marginTop: "20px",
                    marginBottom: "20px",
                    backgroundColor: "#333",
                    color: "#fff",
                    padding: "20px",
                  }}
                >
                  <Typography style={{ fontSize: "20px" }}>
                    Monto: ${gifCardInformation.amount}
                  </Typography>
                  <Typography style={{ fontSize: "20px" }}>
                    Estatus: {gifCardInformation.status}
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </div>
            <Button variant="contained" onClick={validateGifCard}>
              Validar Codigo de Tarjeta de Regalo
            </Button>
          </>
        ) : (
          ""
        )}
        {paymentMethod === "vales" ? (
          <>
            <Typography>
              Recuerda que pagar con vales no se da cambio.
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <InputLabel htmlFor="outlined-adornment-amount">
                Monto a Pagar
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
                label="Amount"
                type="number"
                value={amountToPay}
                onChange={(event) => {
                  setAmountToPay(event.target.value);
                }}
              />
            </FormControl>
          </>
        ) : (
          ""
        )}
                {paymentMethod === "usd" ? (
                  <>
                    <FormControl sx={{ m: 1 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">
                        Monto a Pagar
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={
                          <InputAdornment position="start">$</InputAdornment>
                        }
                        label="Amount"
                        type="number"
                        value={amountToPayUsd}
                        onKeyDown={handleEnter}
                        onChange={(event) => {
                          setAmountToPayUsd(event.target.value);
                          setAmountToPay((Number(event.target.value) * exchangeRate).toFixed(2));
                        }}
                      />
                    </FormControl>

                    {amountToPay !== "" ? (
                      <>
                        {parseFloat(
                          (total 
                            - payments.reduce((acc, curr) => acc + curr.amount, 0)
                            - parseFloat(amountToPay)).toFixed(2)
                        ) > 0 ? (
                          <>{}</>
                        ) : (
                          <>
                            <Typography style={{ marginTop: "20px" }}>
                              {"Cambio"}
                            </Typography>
                            <Typography fontSize={35}>
                              ${" "}
                              {amountToPay.toString() === "NaN"
                                ? "0.00"
                                : (parseFloat(amountToPay) - (total - payments.reduce((acc, curr) => acc + curr.amount, 0))).toFixed(
                                    2
                                  )}{" "}
                              MXN
                            </Typography>
                          </>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}

        <div>
          {payments.length > 0 && (
            <div>
              <Typography style={{ marginTop: "20px" }}>Pagos:</Typography>

              <TableContainer component={Paper} style={{ maxHeight: "600px" }}>
                <Table
                  sx={{ minWidth: "80%" }}
                  aria-label="simple table"
                  style={{ backgroundColor: "#333", color: "#fff" }}
                >
                  {payments.map((payment, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: "20px" }}
                        >
                          {mapPaymentsMethods[payment.payment_method]}
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: "20px" }}
                        >
                          {" "}
                          $ {payment.amount}
                        </TableCell>
                        <TableCell
                          align="left"
                          component="th"
                          scope="row"
                          style={{ fontSize: "20px" }}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={() => deletePayment(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
        <div style={{ marginTop: "20px" }}>
          <Button
            variant="contained"
            size="large"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          {/* {parseFloat(amountToPay) <=
          total - payments.reduce((acc, curr) => acc + curr.amount, 0) ? (
          // total - payments.reduce((acc, curr) => acc + (typeof curr.amount === "string" ? parseFloat(curr.amount) : curr.amount), 0) ? ( */}

          {amountToPay && total - payments.reduce((acc, curr) => acc + curr.amount, 0) > 0
             ? (
            // total - payments.reduce((acc, curr) => acc + (typeof curr.amount === "string" ? parseFloat(curr.amount) : curr.amount), 0) ? (

            <>
              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: "20px" }}
                onClick={() => addPayment()}
              >
                Agregar Pago
              </Button>
            </>
          ) : (
            <>
              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: "20px" }}
                onClick={() => payOrder()}
                disabled={disableSend}
              >
                Terminar Pagos
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
