import React, { useEffect, useState } from "react";
import { useLocalStorage } from "../../auth/useLocalStorage";
import { CommonService } from "../../services/CommonService";
import { Dialog, DialogTitle, DialogActions, ToggleButtonGroup, ToggleButton, TextField, Button } from "@mui/material";
import { CustomerService } from "../../services/CustomerService";
import { Map } from "../Map";
import { useModalStore } from "../../context/modalStore";
import { usePosStore } from "../../context/posStore";
import { IProduct, TRequestSale, PaymentsMethods, ICustomer, IPayment } from "../../common/interfaces";
import { SaleService } from "../../services/SaleService";
import Swal from "sweetalert2";
import "@sweetalert2/theme-dark/dark.css";
import withReactContent from "sweetalert2-react-content";
import { v4 } from "uuid";
import { set } from "lodash";
const MySwal = withReactContent(Swal);

export const OrderOptions: React.FC = () => {
  const modalManager = useModalStore();
  const clearOrder = usePosStore((state) => state.clearOrder);
  // const posCustomer = usePosStore((state) => state.customer);
  const order = modalManager.params?.order;
  const customer = modalManager.params?.customer;
  const pays = modalManager.params?.pays ?? [];

  console.log("Modal params:");
  console.dir(modalManager.params);

  const products = usePosStore((state) => state.products);
  const radioGroupRef = React.useRef<HTMLElement>(null);
  const [phone, setPhone] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [isLocal, setIsLocal] = React.useState(false);
  const [name, setName] = React.useState("");
  const [dir2, setDir2] = React.useState("");
  const [shippedType, setShippedType] = React.useState("");
  const [openDir2, setOpenDir2] = React.useState(false);
  const [showDir, setShowDir] = React.useState(false);
  const [location, setLocation] = React.useState();
  const [shipping, setShipping] = React.useState(0);
  const [time, setTime] = React.useState("");
  const [user, setUser] = useLocalStorage("user", null);
  const [disableSend, setDisableSend] = useState(false);
  // const [customer, setCustomer] = useState<ICustomer>()
  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handlePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
    if (event.target.value.length >= 10) {
      CustomerService.getCustomerByPhone(event.target.value).then((res) => {
        if (res.object?.id && res.object?.direccion !== "") {
          setAddress(res.object?.direccion ?? "");
          if (res.object?.direccion2 !== "") {
            setDir2(res.object?.direccion2 ?? "");
          }
          setName(res.object?.nombre ?? "");
          setOpenDir2(true);
        }
      });
    }
  };
  const handleOk = async () => {
    if (shippedType === "") {
      CommonService.displayAlert("Debe seleccionar un tipo de envío");
      return;
    }
    if (phone === "" && shippedType === "shipped") {
      CommonService.displayAlert("El teléfono es requerido");
      return;
    }
    if (name === "") {
      CommonService.displayAlert("El nombre es requerido");
      return;
    }

    if (isLocal) {
      if (address === "") {
        CommonService.displayAlert("La dirección es requerida");
        return;
      }
    }
    setInfo(
      name,
      phone,
      address,
      dir2,
      isLocal,
      shipping,
      shippedType,
      state,
      location,
      time
    );
    setPhone("");
    setAddress("");
    setDir2("");
    setName("");
    setOpenDir2(false);
    setIsLocal(false);
    setShippedType("");
    setShipping(0);
    setShowDir(false);
    setTime("")
    // onClose("OK");
  };

  const setInfo = async (
    nombre: string,
    phone: string,
    address: string,
    dir2: string,
    isLocal: boolean,
    shipping: number,
    shippedType?: string,
    state?: {
      checkDir: boolean;
      checkDir2: boolean;
    },
    location?: any,
    time?: string
  ) => {
    setDisableSend(true);
    if(customer && customer.rfc !== 'XAXX010101000'){
      console.log({customer})
      await CustomerService.updateCustomerById(customer.id, {
        nombre,
        direccion: address,
        direccion2: dir2,
        telefono: phone,
      });
      createSale(customer, shipping, shippedType, state, location, time)
      return
    }
    if (phone) {
      await CustomerService.getCustomerByPhone(phone).then(async (res) => {
        if (res.object) {
          await CustomerService.updateCustomerByPhone({
            nombre,
            telefono: phone,
            direccion: address,
            direccion2: dir2,
          });
          createSale(res.object, shipping, shippedType, state, location, time)
        } else {
          await CustomerService.createCustomer({
            nombre,
            rfc: "XAXX010101000",
            direccion: address,
            telefono: phone,
          }).then((res) => {
            createSale(res.object, shipping, shippedType, state, location, time)
          });
        }
      });
    } else {
      await CustomerService.createCustomer({
        nombre,
        rfc: "XAXX010101000",
        direccion: address,
        telefono: phone,
      }).then((res) => {
        createSale(res.object, shipping, shippedType, state, location, time)
        // setCustomer(res.object as ICustomer);
        // setCrateWN("WN");
        // setOpenToOrder(false);
        // setOpenModal(true);
        // createOrder();
      });
    }
    setDisableSend(false);
  };

  const createSale = (ncustomer?: ICustomer, shipping?: number, shippedType?: string | undefined, state?: { checkDir: boolean; checkDir2: boolean; } | undefined, location?: any, time?: string | undefined) => {
    setDisableSend(true);
    const auxOrder = products.some(
      (product: IProduct) => product.options && product.options.length > 0
    );
    const data: TRequestSale = {
      orderProducts: products,
      total: order.total,
      subtotal: order.subtotal,
      iva: order.iva,
      ieps: order.ieps,
      descuento: order.discount,
      metodoPago: "other",
      shipping: shipping ?? 0,
      notes_order: order.notes_order,
      schedule: time,
      cliente: ncustomer ?? customer,
      sale_type: "Order",
      payments: pays,
      user_id: user.id,
      warehouse_id: user.warehouse_id,
      cash_received: 0, //parseFloat(amountReceived),
      cashBox: user.cbox,
      notes: address,
      state,
      location,
      shipped_type: shippedType,
    };

    SaleService.createSalePos(data).then((res) => {
      if (res.success) {
        const saleId = res.object?.id ?? 0;
        const paymentTransfer = pays.find(
          (payment: IPayment) => payment.payment_method === PaymentsMethods.Transfer && !payment.is_anticipo)
        const paymentCredit = pays.find(
          (payment: IPayment) => payment.payment_method === PaymentsMethods.Credit);
        if (paymentCredit) {
          const idAuth = v4();
          CommonService.requestAuthorize({
            message: `Se solicita Autorizar un pago a Crédito de $ ${paymentCredit.amount.toFixed(
              2
            )}`, // => $req->message,
            action: {
              type: "authorize-payment",
              item: { order_id: saleId },
              id: idAuth,
              content: `Se solicita Autorizar un pago a Crédito de $ ${paymentCredit.amount.toFixed(
                2
              )}`,
            }, // => $req->action,
            status: "pending", // => 'pending', // Set the initial status
            from: user.id, // => $req->from,
            to: 1, // => $req->to,
          }).then(() => {
            CommonService.toast(
              "Se envío solicitud de Autorización, para validar pago a Crédito",
              "success"
            );
            modalManager.closeModal();
          });
        } else if (paymentTransfer) {
          const idAuth = v4();
          CommonService.requestAuthorize({
            message: `Se solicita Autorizar un pago por Transferencia de $ ${paymentTransfer.amount.toFixed(2)}`, // => $req->message,
            action: {
              type: "authorize-payment",
              item: { order_id: saleId },
              id: idAuth,
              content: `Se solicita Autorizar un pago por Transferencia de $ ${paymentTransfer.amount.toFixed(
                2
              )}`,
            }, // => $req->action,
            status: "pending", // => 'pending', // Set the initial status
            from: user.id, // => $req->from,
            to: 1, // => $req->to,
          }).then(() => {
            CommonService.toast("Se envío solicitud de Autorización, para validar pago por transferencia", "success")
            modalManager.closeModal();
          });
          // pusher.
        } else {
          CommonService.printTicketSale(saleId)
            .then(() => {
              console.log("printing");
              if (auxOrder) {
                CommonService.printTicketOrder(saleId)
                  .then(() => {
                    console.log("printing Comanda");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
            });
          modalManager.closeModal()
          MySwal.fire({
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        clearOrder();
        // setOpenModal(false)
      } else {
        CommonService.toast(res.message, "error");
      }
      setDisableSend(false);
    });
  };

  
  useEffect(() => {
    if (shippedType === "onSite" || shippedType === "inRoom") {
      setIsLocal(false);
      setShipping(0);
    } else {
      setIsLocal(true);
    }
    if (shippedType !== "") setShowDir(true);
  }, [shippedType]);

  const [state, setState] = React.useState({
    checkDir: true,
    checkDir2: false,
  });
  useEffect(() => {
    if(customer && customer.rfc !== 'XAXX010101000'){
      setPhone(customer.telefono)
      setName(customer.nombre)
      setAddress(customer.direccion)
    }
  }, [])
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 800 } }}
      maxWidth="lg"
      TransitionProps={{ onEntering: handleEntering }} 
      open={true}      // open={open}
      // {...other}
    >
      <DialogTitle>Seleccione tipo de Envío</DialogTitle>
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <ToggleButtonGroup
          color="primary"
          aria-label="outlined primary button group"
          exclusive
          value={shippedType}
          onChange={(e: any, value: string) => setShippedType(value)}
        >
          <ToggleButton value="onSite">Recoger en Tienda</ToggleButton>
          <ToggleButton value="inRoom">Comedor</ToggleButton>
          <ToggleButton value="shipped">Domicilio</ToggleButton>
        </ToggleButtonGroup>
      </DialogActions>
      {showDir && (
        <div style={{ marginLeft: "30px", marginRight: "30px" }}>
          <TextField
            margin="normal"
            fullWidth
            name="phone"
            label="Telefono"
            type="phone"
            id="phone"
            value={phone}
            onChange={handlePhone}
          />
          <TextField
            margin="normal"
            fullWidth
            name="name"
            label="Nombre"
            type="name"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="name"
            label="Fecha y Hora"
            type="datetime-local"
            id="name"
            value={time}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => setTime(e.target.value)}
            onFocus={(e) => {
              const target = e.target as HTMLInputElement;
              if (target.showPicker) {
                target.showPicker(); // Safely call the showPicker method
              }
            }}
          />
          {shippedType === "shipped" && (
            <>
              <Map
                setAddress={setAddress}
                setLocation={setLocation}
                orderTotal={Number(order.total ?? 0)}
                setShipping={setShipping}
                address={address}
                defaultPoint={{
                  lat: Number(user.user.warehouse.lat),
                  lng: Number(user.user.warehouse.lng),
                }}
              />
            </>
          )}
        </div>
      )}
      <DialogActions style={{ display: "flex", justifyContent: "center" }}>
        <Button
          autoFocus
          onClick={() => modalManager.closeModal()}
          variant="contained"
          color="error"
          style={{ marginRight: "20px" }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          color="success"
          style={{ color: "#fff" }}
          disabled={disableSend}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}